.fill-default {
    fill: #d0d2d6 !important;
}

.fill-primary {
    fill: #7367f0 !important;
}

.fill-warning {
    fill: #ff9f43 !important;
}

.fill-info {
    fill: #00cfe8 !important;
}

.fill-danger {
    fill: #ea5455 !important;
}

.fill-success {
    fill: #28c76f !important;
}

input[type="date"] {
    color-scheme: dark;
}